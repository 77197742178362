@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600&display=swap");

:root {
  --vw-scale: 1;
}


.vp-title {
  display: none !important;
}

html {
  font-family: "DM Sans", sans-serif;
}

.font-bold {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}

h3 {
  font-family: "DM Sans", sans-serif;
  font-weight: 600 !important;
}

.round {
  border-radius: 2rem;
}

.grad {
  background: rgba(149, 212, 96, 0.2);
  background: linear-gradient(180deg,
      #f7fbfc 0%,
      #fbfcf6 34%,
      #fefcf5 67%,
      #fffef7 100%);
}

body {
  background: rgba(149, 212, 96, 0.2);
  background: linear-gradient(180deg,
      rgba(79, 154, 218, 0.05) 0%,
      rgba(149, 212, 96, 0.05) 17%,
      rgba(255, 195, 48, 0.05) 34%,
      rgba(255, 249, 149, 0.05) 50%,
      rgba(255, 195, 48, 0.05) 67%,
      rgba(149, 212, 96, 0.05) 84%,
      rgba(79, 154, 218, 0.05) 100%);
}

.hamburger-react {
  margin-right: -0.8rem !important;
}

h3::before {
  content: "";
  display: block;
  height: 4em;
  margin-top: -4em;
  visibility: hidden;
}

@media (min-width: 800px) {
  .main {
    transform: scale(var(--vw-scale));
    transform-origin: top;
  }
}

.swiper-pagination-bullet-active {
  background: #fff884 !important;
}

:root {
  --swiper-theme-color: #000 !important;
}